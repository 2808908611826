import { useState } from 'react';
import { UilCheck, UilCopy } from '@iconscout/react-unicons';
import { subHours } from 'date-fns';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { format, formatDate } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { Event, View } from 'react-big-calendar';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Popover, PopoverClose, PopoverContent, PopoverPortal, PopoverTrigger } from '@/components/ui/popover';

import useAuth from '@/hooks/useAuth';

import { TMeetings } from '@/services/meetings';

import { cn } from '@/lib/utils';

import RemoveMeetingButton from '../components/remove-meeting-button';
import RescheduleButtonForOwner from '../components/reschedule-buton-for-owner';

export interface IBusinessEvent extends Event {
  data: TMeetings[number];
}
interface ICustomEventProps {
  view: View;
  event: IBusinessEvent;
}

const EventPopoverContent: React.FC<{ event: IBusinessEvent }> = ({ event }) => {
  const { user } = useAuth();
  const isMeetingOwner = user?.app_user_id === event.data.owner_id;

  if (!event.start || !event.end) {
    return null;
  }

  // Format strings
  const dayAndDateFormat = 'EEEE, MMMM d'; // Format for day and date
  const timeFormat = 'h:mm a'; // Format for time in 12-hour format

  // Format the start date
  const formattedDayAndDate = format(event.start, dayAndDateFormat);
  const formattedStartTime = format(event.start, timeFormat);

  // Format the end time
  const formattedEndTime = format(event.end, timeFormat);

  // Combine the formatted strings
  const formattedString = `${formattedDayAndDate} • ${formattedStartTime} - ${formattedEndTime}`;

  const hasPassed = isBefore(subHours(event.start, 1), new Date());

  const joinMeeting = () => {
    if (!event.data.meeting_link) return;
    window.open(event.data.meeting_link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold">{event.data.post?.title}</h3>
        <PopoverClose />
      </div>
      <div className="flex gap-4">
        <div className="flex size-[1.875rem] min-h-[1.875rem] min-w-[1.875rem] items-center justify-center rounded bg-primary-success-100">
          <UilCheck className="size-5 text-primary-white-100" />
        </div>
        <div className="max-w-[80%] font-medium 3xl:max-w-[85%]">
          <h6 className="truncate text-xl text-primary-dark-100">{event.title}</h6>
          <p className="truncate text-primary-dark-60">{formattedString}</p>
        </div>
      </div>
      {event?.data.meeting_link && (
        <div className="space-y-4">
          <p className="font-medium text-primary-dark-60">Interview link:</p>
          <div className="flex items-center justify-between gap-4 rounded-lg bg-primary-blue-5 p-3">
            <span className="truncate font-medium leading-normal text-primary-blue-100">
              {event?.data.meeting_link}
            </span>
            <Button
              variant={'ghost'}
              className="m-0 h-auto p-0"
              onClick={() => navigator.clipboard.writeText(event?.data.meeting_link || '')}
            >
              <UilCopy className="text-primary-blue-100" />
            </Button>
          </div>
        </div>
      )}
      <div className="space-y-4">
        <p className="font-medium text-primary-dark-60">You are hosting this interview for:</p>
        <div className="flex items-center gap-4">
          <Avatar src={event?.data?.candidate?.image_url} />
          <span className="text-xl font-semibold text-primary-dark-100">{event?.data?.candidate?.name}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 pt-4">
        {isMeetingOwner ? (
          <RescheduleButtonForOwner
            meeting={event.data}
            disabled={hasPassed}
            companyName={event.data.candidate?.name || ''}
          />
        ) : (
          <RemoveMeetingButton meetingId={event.data.id} />
        )}
        <Button
          onClick={joinMeeting}
          className="w-full"
        >
          Join Meeting
        </Button>
      </div>
    </div>
  );
};

const BusinessEvent: React.FC<ICustomEventProps> = ({ event, view }) => {
  const [open, setOpen] = useState(false);

  if (!event.start || !event.end) {
    return null;
  }

  const isMeetingMoreThan15Mins = differenceInMinutes(event.end, event.start) > 15;

  const showTime = view === 'day' || view === 'week';

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
    >
      <PopoverTrigger
        className={cn(
          'flex h-full w-full gap-2 truncate rounded-lg bg-primary-success-10 ring-offset-white transition-colors data-[state=open]:bg-primary-success-20 hover:bg-primary-success-20 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-success-100',
          view === 'month' ? 'p-1' : 'p-2',
          (!showTime || !isMeetingMoreThan15Mins) && 'items-center'
        )}
      >
        <div className="flex size-[1.125rem] min-h-[1.125rem] min-w-[1.125rem] items-center justify-center rounded bg-primary-success-100">
          <UilCheck className="size-3 text-primary-white-100" />
        </div>
        <div className="max-w-[80%] text-start text-xs font-medium 3xl:max-w-[85%]">
          <h6 className={cn('truncate text-primary-dark-100', showTime && !isMeetingMoreThan15Mins && 'mt-0.5')}>
            {event.title}
            {showTime && !isMeetingMoreThan15Mins && (
              <span className="text-primary-dark-60">
                • {formatDate(event.start, 'h:mm a')} {'-'} {formatDate(event.end, 'h:mm a')}
              </span>
            )}
          </h6>
          {showTime && isMeetingMoreThan15Mins && (
            <p className="truncate text-primary-dark-60">
              {formatDate(event.start, 'h:mm a')} {'-'} {formatDate(event.end, 'h:mm a')}
            </p>
          )}
        </div>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          className="min-w-[32.75rem] rounded-2xl p-8 shadow-2xl"
          side={view === 'day' ? 'bottom' : 'right'}
        >
          <EventPopoverContent event={event} />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export default BusinessEvent;
