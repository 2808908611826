import { useCallback, useMemo, useState } from 'react';
import {
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';

import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business';
import { usePipelineActions } from '@/hooks/pipeline/pipeline.async';

// import { toast } from '@/components/ui/toaster';

import { TCandidateMatchStatus } from '@/services/candidate';
import {
  PIPELINE_ENDPOINTS,
  TCandidateForPipelineBoard,
  TGetAllCandidatesForPipelineResponseForBoard,
} from '@/services/pipeline';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

import { cn } from '@/lib/utils';

import CandidateCard from './candidate-card';
import NextStepModal, { TNextStepModalStatus } from './next-step-modal';
import OfferSentModal from './offer-sent-modal';
import RejectCandidateModal, { TRejectCandidateForm } from './reject-candidate-modal';
import StatusColumn from './status-columns';
import { DndElementType, TDndData } from './types';
import { columns } from './utils';

interface IProps {
  isViewOnly?: boolean;
  className?: string;
  candidates: TCandidateForPipelineBoard[];
  isLoading?: boolean;
  queryKey1: QueryKey;
}

const BusinessPipelineBoard: React.FC<IProps> = ({ queryKey1, className, candidates, isLoading, isViewOnly }) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openNextStepModal, setOpenNextStepModal] = useState(false);
  const [nextStepModalStatus, setNextStepModalStatus] = useState<TNextStepModalStatus | undefined>(undefined);
  const [openOfferSentModal, setOpenOfferSentModal] = useState(false);

  const [activeCandidate, setActiveCandidate] = useState<TCandidateForPipelineBoard | null>(null);
  const [originalCandidateStatus, setOriginalCandidateStatus] = useState<TCandidateMatchStatus | undefined>(undefined);

  const queryClient = useQueryClient();
  const queryKey = [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, ...queryKey1];

  const sensors = useSensors(
    useSensor(TouchSensor, { activationConstraint: { distance: 30 } }),
    useSensor(PointerSensor, { activationConstraint: { distance: 30 } })
  );

  const { data: business } = useGetCurrentBusiness({});

  // Pipeline mutations for actions
  const { shortlist, hire, reinstate, reject } = usePipelineActions({
    queryKey,
  });

  const columnsWithData = useMemo(() => {
    return columns.map(({ label, status }) => ({
      label,
      status: status[0],
      data: candidates.filter((item) => {
        if (!item?.metadata?.candidate_status) return false;
        return status.includes(item.metadata.candidate_status);
      }),
    }));
  }, [candidates]);

  const cancelDragInCache = () => {
    queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
      ...oldData,
      items: oldData.items.map((item) => {
        if (item.cardId === activeCandidate?.cardId) {
          return {
            ...item,
            metadata: {
              ...item.metadata,
              candidate_status: activeCandidate?.metadata?.candidate_status,
            },
          };
        }
        return item;
      }),
    }));

    setActiveCandidate(null);
  };

  const proceedDragInCache = (overStatus: TCandidateMatchStatus) => {
    queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
      ...oldData,
      items: oldData.items.map((item) => {
        if (item.cardId === activeCandidate?.cardId) {
          return {
            ...item,
            metadata: {
              ...item.metadata,
              candidate_status: overStatus,
            },
          };
        }
        return item;
      }),
    }));

    setActiveCandidate(null);
  };

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { id } = event.active;
      const candidate = candidates.find((item) => item.cardId === id);

      if (!candidate) return;
      setActiveCandidate(candidate);
      setOriginalCandidateStatus(candidate.metadata?.candidate_status);
    },
    [candidates, isViewOnly]
  );

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      if (!active || !over) return;

      const candidateId = active?.id as string;
      const overData = over.data.current as TDndData;

      const overStatus =
        overData?.type === DndElementType.CARD
          ? candidates.find((item) => item.cardId === over.id)?.metadata?.candidate_status
          : (over.id as TCandidateMatchStatus);

      const queryKey = [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, ...queryKey1];
      queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
        ...oldData,
        items: oldData.items.map((item) => {
          if (item.cardId === candidateId) {
            return {
              ...item,
              metadata: {
                ...item.metadata,
                candidate_status: overStatus,
              },
            };
          }
          return item;
        }),
      }));
    },
    [queryClient, candidates, queryKey1]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;
    if (!over) return;

    const overData = over.data.current as TDndData;

    const overStatus =
      overData?.type === DndElementType.CARD
        ? candidates.find((item) => item.cardId === over.id)?.metadata?.candidate_status
        : (over.id as TCandidateMatchStatus);

    if (originalCandidateStatus === overStatus) return;

    if (originalCandidateStatus && business?.id && activeCandidate) {
      if (overStatus === ROLE_CANDIDATE_STATUS.APPLIED) {
        reinstate.mutateAsync({
          params: {
            path: {
              businessId: business?.id?.toString(),
              postId: activeCandidate?.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
        });
      }

      if (overStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE) {
        shortlist.mutateAsync({
          params: {
            path: {
              businessId: business?.id?.toString(),
              postId: activeCandidate?.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
        });
      }

      if (overStatus === ROLE_CANDIDATE_STATUS.HIRED) {
        hire.mutateAsync({
          params: {
            path: {
              businessId: business?.id?.toString(),
              postId: activeCandidate?.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
        });
      }
    }

    if (overStatus === 'INTERVIEWING') {
      setNextStepModalStatus('INTERVIEWING');
      return setOpenNextStepModal(true);
    }

    if (overStatus === 'OFFER') {
      setNextStepModalStatus('OFFER');
      return setOpenNextStepModal(true);
    }

    if (overStatus === 'REJECT_CANDIDATE') {
      return setOpenRejectModal(true);
    }

    if (overStatus) proceedDragInCache(overStatus);
  };

  const rejectSuccessHandler = (data: TRejectCandidateForm) => {
    if (activeCandidate && business?.id && originalCandidateStatus) {
      reject.mutateAsync(
        {
          params: {
            path: {
              businessId: business.id.toString(),
              postId: activeCandidate.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
          body: {
            /** Send reject reason here  */
            reject_reason: data.reason,
            description: data.description,
          },
        },
        {
          onSuccess() {
            toast.success('Candidate rejected');
          },
        }
      );
    }
  };

  const renderDragOverlay = createPortal(
    <DragOverlay
      zIndex={30}
      dropAnimation={{ ...defaultDropAnimation, duration: 120, easing: 'cubic-bezier(0.32, 0, 0.67, 0)' }}
    >
      {activeCandidate ? (
        <CandidateCard
          isViewOnly={isViewOnly}
          candidate={candidates.find((candidate) => candidate.cardId === activeCandidate.cardId)!}
        />
      ) : null}
    </DragOverlay>,
    document.body
  );

  const renderColumns = () => {
    if (isLoading)
      return columns.map((column) => (
        <Skeleton
          key={column.label}
          className="h-[70dvh] w-[20.625rem]"
        />
      ));

    return columnsWithData.map(({ label, status, data }) => (
      <StatusColumn
        key={status}
        status={status}
        label={label}
        data={data}
        isViewOnly={isViewOnly}
        setOriginalCandidateStatus={setOriginalCandidateStatus}
        setActiveCandidate={setActiveCandidate}
        setOpenNextStepModal={setOpenNextStepModal} //To pass the openNextStepModal function to the candidate card
      />
    ));
  };

  return (
    <>
      <ScrollArea className={cn('h-full w-[calc(100vw-296px)] 3xl:w-[calc(100vw-330px)]', className)}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className="flex gap-4 px-8">{renderColumns()}</div>
          {renderDragOverlay}
        </DndContext>
        <ScrollBar
          orientation="horizontal"
          className="hidden"
        />
      </ScrollArea>
      <RejectCandidateModal
        open={openRejectModal}
        onOpenChange={setOpenRejectModal}
        onCancel={cancelDragInCache}
        onSuccess={rejectSuccessHandler}
        // candidateId={candidateId}
        // postId={data[0].application_meta!.post_id!}
      />
      {activeCandidate && (
        <NextStepModal
          candidate={activeCandidate}
          originalCandidateStatus={originalCandidateStatus}
          business={business}
          open={openNextStepModal}
          onOpenChange={setOpenNextStepModal}
          onInterviewSuccess={() => {
            setOpenNextStepModal(false);
          }}
          onInterviewCancel={() => {
            cancelDragInCache();
            setOpenNextStepModal(false);
          }}
          onOfferSuccess={() => {
            setOpenNextStepModal(false);
            setTimeout(() => {
              setOpenOfferSentModal(true);
            }, 200);
          }}
          onOfferCancel={() => {
            cancelDragInCache();
            setOpenNextStepModal(false);
          }}
          status={nextStepModalStatus}
        />
      )}
      <OfferSentModal
        open={openOfferSentModal}
        onOpenChange={setOpenOfferSentModal}
      />
    </>
  );
};

export default BusinessPipelineBoard;
