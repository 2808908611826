import { useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { UilEye } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import { dotVariants } from '@/components/ui/tag';

import { statusToTagVariantMap } from '@/components/tags/match-status-tag';

import { TCandidateMatchStatus } from '@/services/candidate';
import { TCandidateForPipelineBoard } from '@/services/pipeline';

import { cn } from '@/lib/utils';

import CandidateCard from './candidate-card';
import { DndElementType } from './types';

interface IStatusColumnProps {
  status: TCandidateMatchStatus;
  label: string;
  data: TCandidateForPipelineBoard[];
  isViewOnly?: boolean;
  setActiveCandidate?: (candidate: TCandidateForPipelineBoard) => void;
  setOpenNextStepModal?: (open: boolean) => void;
  setOriginalCandidateStatus?: (status: TCandidateMatchStatus | undefined) => void;
}

const StatusColumn: React.FC<IStatusColumnProps> = ({
  label,
  status,
  data,
  isViewOnly,
  setActiveCandidate,
  setOpenNextStepModal,
  setOriginalCandidateStatus,
}) => {
  const [hidden, setHidden] = useState(false);
  const { setNodeRef } = useDroppable({
    id: status!,
    data: {
      type: DndElementType.COLUMN,
    },
    disabled: hidden,
  });

  if (!status) return null;

  const dotVariant = statusToTagVariantMap[status];

  const toggleHidden = () => {
    setHidden(!hidden);
  };

  return (
    <SortableContext
      id={status[0]}
      items={data}
      strategy={verticalListSortingStrategy}
      disabled={hidden}
    >
      <section
        className={cn('rounded-2xl bg-primary-dark-5 p-4', hidden ? 'max-h-16 w-24' : 'w-[20.625rem]')}
        ref={setNodeRef}
      >
        <header className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <div className={cn(dotVariants({ variant: dotVariant }), 'size-2')}></div>
            {!hidden && (
              <>
                <h2 className="text-xl font-semibold text-primary-dark-60">{label}</h2>
                <div className="-ml-[1px] flex min-h-6 min-w-6 items-center justify-center rounded-full bg-primary-dark-10 px-2 py-0.5 text-sm font-semibold text-primary-dark-120">
                  <span>{data.length}</span>
                </div>
              </>
            )}
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleHidden}
          >
            <UilEye className="default-icon" />
          </Button>
        </header>
        {data.length > 0 && !hidden && (
          <div className="flex flex-col gap-3 pt-3">
            {data.map((candidate) => (
              <CandidateCard
                isViewOnly={isViewOnly}
                candidate={candidate}
                key={candidate.id + candidate.job_post.id}
                openNextStepModal={() => {
                  setActiveCandidate?.(candidate);
                  setOpenNextStepModal?.(true);
                  setOriginalCandidateStatus?.(candidate.metadata?.candidate_status);
                }}
              />
            ))}
          </div>
        )}
      </section>
    </SortableContext>
  );
};

export default StatusColumn;
