import { useEffect, useState } from 'react';
import { UilBox } from '@iconscout/react-unicons';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';

import CandidateRolesTable from '@/components/roles/candidate-roles-table';

import { useGetCurrentCandidateAppliedMatches } from '@/hooks/candidate';
import useFilters from '@/hooks/useFilters';

import { TCandidatePostsFilters, TGetCurrentCandidateAppliedSort } from '@/services/candidate';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/roles/applied')({
  component: Applied,
  validateSearch: () => ({}) as TCandidatePostsFilters,
});

const ArchivedButton = () => {
  const navigate = useNavigate();
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.rolesButtons));
  }, []);

  if (!portalNode) return null;

  return createPortal(
    <Button
      variant="tertiary"
      onClick={() =>
        navigate({
          to: '/candidate/archived',
        })
      }
    >
      <span>Archived</span>
      <UilBox className="size-[1.125rem] text-primary-dark-100" />
    </Button>,
    portalNode
  );
};

function Applied() {
  const { filters, setFilters } = useFilters<TCandidatePostsFilters>(Route.id);

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const sortQuery = sortByToQuery<TGetCurrentCandidateAppliedSort>(filters.sortBy);

  const { data, isPending } = useGetCurrentCandidateAppliedMatches({
    reactQueryOptions: {
      queryKey: ['applied', filters],
    },
    params: {
      query: {
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        q: filters.query,
        ...(sortQuery
          ? {
              sort: sortQuery.id,
              order: sortQuery.order,
            }
          : {
              sort: 'created_at',
              order: 'desc',
            }),
      },
    },
  });

  return (
    <>
      <ArchivedButton />
      <CandidateRolesTable
        data={data}
        isPending={isPending}
        filters={filters}
        setFilters={setFilters}
        paginationState={paginationState}
      />
    </>
  );
}
