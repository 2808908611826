import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  addOwnerAvailability,
  cancelMeetingById,
  candidateBookMeeting,
  createMeetingDraft,
  MEETING_ENDPOINTS,
  ownerRequestReschedule,
  removeMeetingFromUserCalendar,
  rescheduleMeeting,
  TAddOwnerAvailability,
  TCancelMeetingRequest,
  TCandidateBookMeeting,
  TCreateMeetingDraft,
  TOwnerRequestReschedule,
  TRescheduleMeeting,
  TUpdateMeetingOwnerAvailability,
  updateMeetingOwnerAvailability,
} from '@/services/meetings';
import { PIPELINE_ENDPOINTS } from '@/services/pipeline';

import { UseMutationOptions } from '../types';

export function useCancelMeeting({ reactQueryOptions }: UseMutationOptions<TCancelMeetingRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: cancelMeetingById,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
        exact: false,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_UPCOMING_MEETINGS],
        exact: false,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_CANDIDATE_MEETING_FOR_POST],
        exact: false,
        refetchType: 'all',
      });
    },
  });
}

export function useCreateMeetingDraft({ reactQueryOptions }: UseMutationOptions<TCreateMeetingDraft>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: createMeetingDraft,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES],
      });
    },
  });
}

export function useAddOwnerAvailability({ reactQueryOptions }: UseMutationOptions<TAddOwnerAvailability>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addOwnerAvailability,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES],
      });
    },
  });
}

export function useUpdateMeetingOwnerAvailability({
  reactQueryOptions,
}: UseMutationOptions<TUpdateMeetingOwnerAvailability>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMeetingOwnerAvailability,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_MEETING_TIME_SLOTS],
      });
    },
  });
}

export function useCandidateBookMeeting({ reactQueryOptions }: UseMutationOptions<TCandidateBookMeeting>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: candidateBookMeeting,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_CANDIDATE_MEETING_FOR_POST],
        exact: false,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
        exact: false,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_UPCOMING_MEETINGS],
        exact: false,
        refetchType: 'all',
      });
    },
  });
}

export function useRescheduleMeeting({ reactQueryOptions }: UseMutationOptions<TRescheduleMeeting>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: rescheduleMeeting,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
      });
    },
  });
}

export function useRemoveMeetingFromUserCalendar() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeMeetingFromUserCalendar,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
      });
    },
  });
}

export function useOwnerRequestReschedule({ reactQueryOptions }: UseMutationOptions<TOwnerRequestReschedule>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: ownerRequestReschedule,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
      });
    },
  });
}
